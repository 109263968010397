export const texts = {
    ABOUT_ME: 'à propos de moi',
    APPLICATIONS: 'applications',
    AVATAR: 'avatar',
    BY_AXEL_MILLIERY: 'Par Axel Milliery',
    COMMITS: 'commits',
    CONTRIBUTORS: 'contributeurs',
    DARK: 'sombre',
    DOWNLOADS: 'téléchargements',
    EXAMPLES: 'exemples',
    FOLLOWERS: 'abonnés',
    GITHUB_PROFILE: 'Profil GitHub',
    GITHUB_REPO: 'Dépôt GitHub',
    JOINED: 'à rejoint',
    LANGUAGES: 'languages',
    LAST_PUSH: 'dernier push',
    LICENSE: 'license',
    LIGHT: 'clair',
    MISCELLANEOUS: 'divers',
    PACKAGES: 'paquets',
    PAGE_UNAVAILABLE_IN_LANGUAGE: 'Cette page n\'est pas disponible dans le language sélectionnée.',
    PORTFOLIO_AND_CODE_DEMO: 'Portfolio et démo de projets',
    PUBLIC_REPOS: 'dépôts publics',
    RELEASE: 'publication',
    RELEASES: 'releases',
    SETTINGS: 'paramètres',
    SIZE: 'taille',
    STARGAZERS_COUNT: 'nombre d\'étoiles',
    THEME_COLORS: 'couleurs',
    THEME_OPTIONS: 'mode',
    TOPICS: 'topiques',
    VERSION: 'version',
    README: 'readme',
    README_FILE_NOT_AVAILABLE_IN_LANGUAGE: 'Ce fichier README n\'est pas encore disponbile dans la langue sélectionnée.',
    WEBSITE: 'site web',
    WEBSITES: 'sites web',
}

export default texts
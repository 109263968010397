export const texts = {
    ABOUT_ME: 'about me',
    APPLICATIONS: 'applications',
    AVATAR: 'avatar',
    BY_AXEL_MILLIERY: 'By Axel Milliery',
    COMMITS: 'commits',
    CONTRIBUTORS: 'contributors',
    DARK: 'dark',
    DOWNLOADS: 'downloads',
    EXAMPLES: 'examples',
    FOLLOWERS: 'followers',
    GITHUB_PROFILE: 'GitHub Profile',
    GITHUB_REPO: 'GitHub Repo',
    JOINED: 'joined',
    LANGUAGES: 'languages',
    LAST_PUSH: 'last push',
    LICENSE: 'license',
    LIGHT: 'light',
    MISCELLANEOUS: 'miscellaneous',
    PACKAGES: 'packages',
    PAGE_UNAVAILABLE_IN_LANGUAGE: 'This page is not available in the selected language',
    PORTFOLIO_AND_CODE_DEMO: 'Portfolio and projects demo',
    PUBLIC_REPOS: 'public repos',
    RELEASE: 'release',
    RELEASES: 'releases',
    SETTINGS: 'settings',
    SIZE: 'size',
    STARGAZERS_COUNT: 'stargazers count',
    THEME_COLORS: 'colors',
    THEME_OPTIONS: 'mode',
    TOPICS: 'topics',
    VERSION: 'version',
    README: 'readme',
    README_FILE_NOT_AVAILABLE_IN_LANGUAGE: 'This README file is not available in the selected language.',
    WEBSITE: 'website',
    WEBSITES: 'websites',
}

export default texts